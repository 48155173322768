
import { Vue, Component, Prop } from 'vue-property-decorator';
import InputJpDate from '@/components/Parts/InputJpDate.vue';
import UserBaseInfo from '@/model/user/UserBaseInfo';
import Status from '@/model/user/Status';
import TypeJpDate from '@/model/type/TypeJpDate';

@Component({
  components: {
    InputJpDate
  }
})
export default class EngineerListInfo extends Vue {
  @Prop()user!:UserBaseInfo;
  status:Status = this.user.status
  startDate:TypeJpDate = TypeJpDate.getToday();
  endDate:TypeJpDate= TypeJpDate.getToday();
  historyLogo:boolean = true;
  historydateShowFlg:boolean = true;

  downloadcsv(target:string, startDate:TypeJpDate, endDate:TypeJpDate):void {
    if (!startDate.isValid) return;
    if (!endDate.isValid) return;
    window.open(`/api/v1/${target}/download?userId=${this.user.userId.value}&startDate=${startDate.toDateString()}&endDate=${endDate.toDateString()}`, '_blank');
  }
  historyUrl():void {
    if (!this.startDate.isValid) return;
    if (!this.endDate.isValid) return;
    window.open(`/print.html#/resume/${this.user.userId.value}/${this.historyLogo}/${this.historydateShowFlg}/${this.startDate.toDateString()}/${this.endDate.toDateString()}`, '_blank');
  }
  WorkerListUrl():void {
    window.open(`/print.html#/workerList/${this.user.userId.value}`, '_blank');
  }
  historyExcel():void{
    window.open(`/api/v1/admin/history/createExcel/${this.user.userId.value}`, '_blank');
  }
}


import { Vue, Component } from 'vue-property-decorator';
import { NavigationGuardNext, Route } from 'vue-router';
import { Getter, Mutation } from 'vuex-class';
import UserApi from '@/module/api/UserApi';
import EngineerListInfo from '@/components/User/EngineerListInfo.vue';
import UserInfo from '@/model/user/UserInfo';
import {
  STATUS_VALUE
} from '@/model/user/Status';

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave'
]);
@Component({
  components: {
    EngineerListInfo
  }
})
export default class EngineerList extends Vue {
  @Getter('pageCache') getPageCache!: (key:string)=> any;
  @Mutation('commitPageCache') commitPageCache!: any;

  user:UserInfo[]=[];
  status:number = STATUS_VALUE.AVAILABLE;
  searchKeyWord = '';
  /** 一度に表示する件数 */
  limit: number = 25;
  pageNumber: number = 1;
  displayPath: string = this.$route.path;
  // importしてきた定数をそのままtemplate部で使うと未定義エラーとなるので再定義・・・
  ALL = STATUS_VALUE.ALL;
  INITIAL = STATUS_VALUE.INITIAL;
  AVAILABLE = STATUS_VALUE.AVAILABLE;
  DISABLE = STATUS_VALUE.DISABLE;

  beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext<EngineerList>): void {
    next((vm: EngineerList) => {
      vm.rememberPageView();
    });
  }

  rememberPageView(): void {
    const cache = this.getPageCache(this.displayPath);
    if (cache) {
      this.user = cache.user;
      this.status = cache.status;
      this.searchKeyWord = cache.searchKeyWord;
      this.limit = cache.limit;
      this.pageNumber = cache.pageNumber;
    }
  }

  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext<EngineerList>): void {
    this.commitPageCache(
      {
        pageName: this.displayPath,
        value: {
          user: this.user,
          status: this.status,
          searchKeyWord: this.searchKeyWord,
          limit: this.limit,
          pageNumber: this.pageNumber
        }
      }
    );
    next();
  }

  open(url: string): void {
    window.open(url, '_blank');
  }
  mounted():void{
    if (this.user.length === 0) {
      this.limit = 25;
      this.pageNumber = 1;
      UserApi.findAll().then((res) => {
        this.user = res.data;
      });
    }
  }

  downloadcsv():void{
    let csvHeader = '\ufeffID,氏名,フリガナ,生年月日,性別,血液型,作業服サイズ,ウェストサイズ,自宅住所,最寄り駅,電話番号（自宅）,電話番号（携帯）,メール（PC）,メール（携帯）,ステータス（管理者側のみ）,テクリス番号,資格,経歴,勤務先（管理者側のみ）,最終学歴卒業日,宿舎住所,緊急連絡先,家族構成,家族構成（内扶養者）,年金番号,雇用被保険者番号,配偶者基礎年金番号,PCスキル,PCスキル補足,職歴,趣味特技,職場希望,PLへのお願い事項\n';
    this.filteredByConditionList.forEach((el) => {
      csvHeader += `${el.toCSV}\n`;
    });
    const blob = new Blob([csvHeader], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = '技術者一覧.csv';
    link.click();
  }

  get filteredByConditionList():UserInfo[] {
    let result = this.user;
    if (this.status === STATUS_VALUE.INITIAL) {
      result = result.filter((u) => String(u.userBaseInfo.status.value) === STATUS_VALUE.INITIAL.toString());
    }
    if (this.status === STATUS_VALUE.AVAILABLE) {
      result = result.filter((u) => String(u.userBaseInfo.status.value) === STATUS_VALUE.AVAILABLE.toString());
    }
    if (this.status === STATUS_VALUE.DISABLE) {
      result = result.filter((u) => String(u.userBaseInfo.status.value) === STATUS_VALUE.DISABLE.toString());
    }
    if (this.searchKeyWord !== '') {
      result = result.filter((u) => u.toCSV.match(this.searchKeyWord));
    }
    return result;
  }
  get filteredList():UserInfo[] {
    return this.filteredByConditionList.slice(this.topRow - 1, this.endRow);
  }
  get topRow(): number {
    if (this.dataLength === 0) return 0;
    return this.limit * (this.pageNumber - 1) + 1;
  }
  get endRow(): number {
    const end = this.limit * this.pageNumber;
    if (this.dataLength < end) return this.dataLength;
    return end;
  }
  get pages(): number {
    return Math.ceil(this.dataLength / this.limit);
  }
  get dataLength(): number {
    return this.filteredByConditionList.length;
  }
}
